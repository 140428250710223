import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This website contains links to other websites and content belonging to or originating from third parties or links to websites and features. Any of the above-mentioned external links are not investigated or checked for accuracy, reliability or adequacy by HH Consulting.`}</p>
    <p>{`Links from HH Consulting to third party websites do not constitute an endorsement by HH Consulting of the parties or their products and services.`}</p>
    <p>{`This website contains copyrighted material from `}<a parentName="p" {...{
        "href": "https://unsplash.com/"
      }}>{`unsplash.com`}</a>{`, the use of which has been granted (granted irrevocable, nonexclusive, worldwide copyright license to download, copy, modify and use photos from Unsplash) by Unsplash. This license does not include the right to compile photos from Unsplah to replicate a similar or competing service.`}</p>
    <p>{`The photos shown on this website are not of actual projects of HH Consulting. The photos represent designs similar to work performed in the past and or projects that HH Consulting can assist with by providing the structural consultation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      